.flex {
    display: flex;
}

.font-weight-600 {
    font-weight: 600;
}

.flex-space-between {
    justify-content: space-between;
}