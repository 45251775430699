/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */
.footer-title {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-links {
    list-style: none;
    padding-left: 0;
}

.footer-text {
    color: #fcc90f;
}

.footer-links a {
    border-bottom: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

.box,
a {
    color: #000;
}

#sidebar {
    height: 100%;
    border-left: 2px solid #f1f1f1;
}

#sidebar * li {
    list-style-type: none;
    padding-left: 1rem;
}

#sidebar * a {
    border-bottom: none;
}

@media only screen and (max-width: 980px) {
    #sidebar {
        display: none;
    }
}

.download-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2rem 0;
}

.custom-button {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    border-bottom: none;
}

.custom-button:hover {
    color: _palette(accent, bg);
    transition: all 0.1s linear;
    cursor: pointer;
}

.autocomplete-suggestions {
    text-align: left;
    cursor: default;
    border: 1px solid #ccc;
    border-top: 0;
    background: #fff;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, .1);
    /* core styles should not be changed */
    position: absolute;
    display: none;
    z-index: 99999;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.autocomplete-suggestion {
    position: relative;
    cursor: pointer;
    padding: 7px;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
}

.autocomplete-suggestion b {
    font-weight: normal;
    color: #1f8dd6;
}

.autocomplete-suggestion.selected {
    background: #333;
    color: #fff;
}

.autocomplete-suggestion:hover {
    background: #444;
    color: #fff;
}

.autocomplete-suggestion>.context {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchbox-mobile {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.searchbox-mobile label {
    display: inline;
    color: #fac70d;
    padding: 0.75rem 1rem;
    margin: 0;
}

.searchbox label {
    display: inline;
    color: #fac70d;
}

.searchbox input {
    line-height: 1rem;
}

#mobile-searchbox {
    background-color: #000;
    padding: 0 1rem;
}

img {
    max-width: 100%;
    height: auto;
    width: auto;
}

#nav>ul {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 1400px;
    margin: auto;
    position: relative;
}

@media only screen and (max-width: 1680px) {
    #nav>ul {
        width: 1200px;
    }
}

@media only screen and (max-width: 1280px) {
    #nav>ul {
        width: 960px;
    }
}

@media only screen and (max-width: 980px) {
    #nav>ul {
        width: 95%;
    }
}

#nav>ul>a {
    position: absolute;
    left: 0px;
    border-bottom: none;
}

#sidebar>ul>ul>.current {
    background-color: #d3d3d3;
    font-weight: 600;
}

#sidebar>ul>ul>.current :hover {
    color: #000;
}

#sidebar>ul>ul>li :hover {
    color: #000;
    font-weight: 600;
}

#contact-message {
    display: none;
    margin-bottom: 3rem;
}

#client-logos {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

#client-logos>a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 3rem;
    width: 13rem;
    height: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: #fcc90f;
}

#client-logos>a:hover {
    border: 5px solid;
}

#client-logos>a>img {
    max-height: 100%;
    max-width: 100%;
}

.client-quote {
    margin: 0 5rem;
}

h2.clients-container {
    color: white;
    padding-top: 1rem;
}

header.major p.clients-text {
    color: white;
    font-size: 1.0em;
}

img.client-portrait {
    max-width: 80px;
    margin-right: 5px;
    border-radius: 50%;
}

#title-logo {
    border-bottom: none;
}

@media only screen and (max-width: 980px) {
    #client-logos {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin: auto;
        flex-wrap: wrap;
    }

    #client-logos>a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 3rem;
        width: 10rem;
        height: 5rem;
        padding: 0.5rem 1rem;
        margin: 0.5rem;
    }

    .client-quote {
        margin: 0 2rem;
    }
}

#content {
    margin-left: 1.5rem;
}

// CSS for pricing calculator

.calculator-section {
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.calculator-section * p {
    margin: 0 0.5rem
}

.calculator-container {
    background-color: #fcfcfc;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 45%;
    min-width: 15rem;
    padding: 1rem;
    margin: 0.5rem 0;
}

.calculator-container>h3 {
    margin: 0;
}

.calculator-field-container {
    width: 100%;
    min-width: 15rem;
    margin: 1rem 0;
}

.calculator-range {
    height: 1rem;
}

.calculator-range>input {
    width: 100%;
    appearance: auto;
}

code>span {
    padding-left: 1rem;
}

pre>code {
    font-size: 80%;
    font-family: monospace;
    text-wrap-mode: wrap;
}

article>.highlight {
    margin-bottom: 2rem;
}